.titlebar {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 2px 2px rgba(0,0,0,.2);
}

.logo-container {
  display: flex;
  height: 80px;
  width: 42.5%;
}

.logo-name {
  display: flex;
  flex-direction: column;
}

#logo-tree {
  height: 76px;
  padding: 4px 0 0 40px;
}

#logo-sequoia {
  height: 44px;
  padding: 8px 0 0;
}

#logo-cg {
  height: 20px;
  padding: 4px 0;
}

@media only screen and (max-width: 800px){
  .titlebar {
    height: 64px;
  }

  .logo-container {
    width: auto;
    height: 64px;
  }

  #logo-tree {
    height: 60px;
    padding: 4px 0 0 8px
  }

  .logo-name{
    width: 75%;
  }

  #logo-sequoia {
    height: 36px;
    padding: 8px 0 0;
  }
  
  #logo-cg {
    height: 16px;
    padding: 3px 0 1px;
  }
}