.learn-more-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85vh;
  width: 100%;
  position: relative;
}

/* .learn-more-container > div {
  flex: 1;
} */

/* .learn-profile-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
} */

.learn-profile-card {
  max-width: 80%;
  margin: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
}

.learn-profile-photo {
  min-width: 500px;
  min-height: 400px;
  width: 500px;
  height: 400px;
  max-width: 500px;
  max-height: 400px;
  margin: auto;
  box-shadow: -4px 4px 8px #055520;
  overflow: hidden;
}

.learn-profile-name {
  margin: 2vh 0;
  text-align: center;
}

.learn-profile-title {
  margin: 0 0 2vh 0;
  text-align: center;
}

.learn-profile-about {
  max-width: 50%;
  margin: auto 25%;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}
  
.learn-title-container {
  max-width: 66%;
  margin: 4vh 0;
}

.learn-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  margin: 0 0 0 0;
  text-align: center;
}

.learn-title-underline {
  height: 4px;
  background-color: #055520;
}

.learn-paragraph-container {
  font-family: 'Open Sans', sans-serif;
  min-width: 80%;
  min-height: 53.33%;
  width: 80%;
  height: 53.33%;
  max-width: 80%;
  max-height: 53.33%;
  padding: 2vh 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learn-about-paragraph {
  padding: 0;
  width: 49%;
  margin: auto 5%;
  line-height: 26px;
}

.learn-about-photo-container{
  min-width: 49%;
  min-height: 33.33%;
  width: 49%;
  height: 33.33%;
  max-width: 49%;
  max-height: 33.33%;
  margin: auto;
}

.learn-about-photo {
  min-width: 80%;
  min-height: 100%;
  width: 80%;
  height: 100%;
  max-width: 80%;
  max-height: 100%;
  margin: auto 10%;
  box-shadow: -4px 4px 8px #055520;
}

.learn-list-container {
  font-family: 'Open Sans', sans-serif;
  padding: 0 0;
  max-width: 80%;
}

.learn-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  padding: 0;
}

.learn-list-item-container {
  display: flex;
  width: 50%;
  padding: 2vh 0;
}

.learn-list-icon {
  margin: auto 0;
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  color: rgba(200, 200, 0, 0.54);
}

.learn-list-item {
  margin: auto 0 auto 1vw;
}

@media only screen and (max-width: 800px){
  .learn-title-container {
    margin: 2vh 0;
  }

  .learn-profile-photo {
    min-width: 100%;
    min-height: 80%;
    width: 100%;
    height: 80%;
    max-width: 100%;
    max-height: 80%;
  }

  .learn-profile-about {
    max-width: 100%;
    margin: auto;
  }
  
  .learn-paragraph-container {
    flex-direction: column;
    padding: 0;
  }

  .learn-about-paragraph, .learn-profile-about {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    margin: 2% auto;
  }

  .learn-about-photo-container {
    min-width: 100%;
    min-height: 66.66%;
    width: 100%;
    height: 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    margin: 2% auto;
  }

  .learn-about-photo {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .learn-list {
    flex-direction: column;
  }

  .learn-list-item-container {
    padding: 1vh 0;
    width: 100%;
  }
}