/* .welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95vh;
  width: 100%;
  margin-top: 80px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-image: linear-gradient(
    rgba(0, 0, 0, 33%),
    rgba(0, 0, 0, 33%)
  ),
  url(../../images/SequoiaBackground720.jpg);
} */

.welcome-screen { 
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95vh;
  width: 100%;
  margin-top: 80px;
  background: linear-gradient(
    rgba(0, 0, 0, 0.20),
    rgba(0, 0, 0, 0.20)
  ), url(../../images/SequoiaBackground-desktop.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* IE */
  /* filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../images/SequoiaBackground.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../images/SequoiaBackground.jpg', sizingMethod='scale')"; */
}


.company-intro {
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  text-shadow: -1px 0 #CFB53B, 0 1px #CFB53B, 1px 0 #CFB53B, 0 -1px #CFB53B;
  text-align: center;
  font-size: 3vh;
  max-width: 50%;
}

.intro-1, .intro-2, .intro-3 {
  margin: 0 0 1vh 0;
}

.intro-4 {
  margin: 0 0 2vh 0;
}

.welcome-logo {
  height: 55vh;
  padding: 2vh 0 0 0;
  align-items: center;
}

.more-info {
  display: flex;
  height: 10vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 0;
  z-index: 1;
}

.arrow {
  border: solid #CFB53B;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
  cursor: pointer;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.learn-more {
  min-width: fit-content;
  height: 6vh;
  border-width: 4px;
  border-color: #CFB53B;
  border-style: solid;
  padding: 0 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 2vh;
  text-align: center;
  color: #CFB53B;
  background-color: rgba(0, 0, 0, 0.20);
}

.learn-more:hover {
  color: #ffffff;
  background-color: rgba(200, 200, 200, 0.33);
  cursor: pointer;
}

.learn-more:focus {
  outline: 0;
}

@media only screen and (max-width: 800px){
  .welcome-screen {
    margin-top: 64px;
    background: linear-gradient(
      rgba(0, 0, 0, 0.20),
      rgba(0, 0, 0, 0.20)
    ), url(../../images/SequoiaBackground-mobile.jpg) no-repeat center center; 
  }

  .intro-4 {
    margin-bottom: 4vh;
  }

  .welcome-logo {
    height: auto;
    max-height: 50%;
    max-width: 100%;
    padding: 4vh 0 4vh 0;
  }

  .company-intro {
    max-width: 98%;
    font-size: 2.5vh;
  }
}