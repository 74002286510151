.contact-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85vh;
  width: 100%;
  margin-top: 80px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

/* .contact-screen > div {
  flex: 1;
} */

.contact-intro {
  font-size: 32px;
  color: #055520;
  text-align: center;
}

.contact-description {
  max-width: 64vw;
  padding: 16px;
  margin: auto;
  text-align: center;
}

.message-sent {
  max-width: 64vw;
  padding: 16px;
  margin: 0 auto;
  color: #ff0000;
  text-align: center;
}

.contact-form {
  min-width: 64vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.contact-form > :not(:last-child) {
  margin-bottom: 16px;
}

.contact-form .form-field {
  display: flex;
  flex-direction: column;
}

.contact-form .form-field .input-field {
  height: 32px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

.contact-form .label-content {
  margin-bottom: 4px;
}

.contact-form .input-field, .contact-form .text-area {
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100%;
  border-style: solid;
  border-color: rgb(217, 217, 217);
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

.contact-form .input-field:focus, .contact-form .text-area:focus {
  outline: none !important;
  box-shadow: 0 0 8px #055520;
}

.contact-form label {
  text-align: left;
}

.contact-form .submit-button {
  min-width: fit-content;
  font-size: 16px;
  height: 32px;
  border-radius: 4px;
  border-style: solid;
  padding: 0 32px;
  align-self: flex-end;
  border-width: 4px;
  border-color: #CFB53B;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  color: #055520;
  background-color: #ffffff;
}

.contact-form .submit-button:hover {
  background-color: rgb(207, 181, 59, 50%);
}

@media only screen and (max-width: 800px){
  .contact-screen {
    margin-top: 64px;
  }

  .contact-description {
    text-align: center;
    max-width: 90%;
  }

  .contact-screen > div {
    flex: 1;
    width: 90%;
  }
}