.site-map-container {
  display: flex;
  height: 20vh;
  width: 100%;
  background-color: #5C510B;
  align-items: center;
}

.site-list-container {
  width: 40%;
}

.site-list {
  display: flex;
  list-style-type: none;
  padding-left: 16vw;
}

.site-list-link {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  padding: 0 16px 0 16px;
}

.site-list-link:hover {
  color: #CFB53B;
}

.between-list-item {
  background-color: #ffffff;
  height: 20px;
  width: 2px;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  max-height: inherit;
  height: 6vh;
  min-width: 45%;
  word-break: break-all;
}

.contact-info :nth-child(-n+2) {
  text-align: right;
  margin-right: 32px;
}

.contact-name {
  height: 50%;
  margin: 0;
}

.contact-title {
  height: 50%;
  margin: 0;
}

.contact-phone {
  height: 50%;
  margin: 0;
}

.contact-email {
  height: 50%;
  color: #ffffff;
  margin: 0;
}

.sequoia-logo-container {
  max-width: 15%;
}

.sequoia-logo {
  height: 15vh;
}

@media only screen and (max-width: 800px){
  .site-map-container {
    flex-direction: column;
    height: 22vh;
  }

  .site-list-container {
    width: 100%;
    height: auto;
  }

  .site-list {
    justify-content: center;
    padding-left: 0;
  }

  .contact-info {
    flex-direction: row;
    justify-content: center;
  }

  .contact-info :nth-child(-n+2) {
    margin-right: 0;
    text-align: center;
    width: 100%;
  }

  .contact-info :nth-child(n+2) {
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
}

@media only screen and (max-width: 1000px){
  .sequoia-logo {
    display: none;
  }
}