.menu {
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.dropdown-content {
  display: flex;
}

.menu-button {
  display: flex;
  min-width: fit-content;
  height: 80px;
  padding: 0 2vw;
  align-items: center;
  justify-content: center;
}

.menu .menu-button.active a {
  color: #CFB53B;
}

.menu-button a {
  font-family: 'Open Sans', sans-serif;
  color: #055520;
  font-weight: bold;
  text-decoration: none;
}

.menu-button a:hover {
  color: #CFB53B;
}

@media only screen and (max-width: 800px){
  .menu-container {
    display: inline-block;
    cursor: pointer;
  }
  
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #055520;
    margin: 6px 0;
    transition: 0.3s;
  }
  
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
  
  .dropdown-content.no-show {
    display: none;
  }

  .dropdown-content.show.slide-out {
    -webkit-animation: slideout .5s; /* Safari and Chrome */
    -moz-animation: slideout .5s; /* Firefox */
    -ms-animation: slideout .5s; /* Internet Explorer */
    -o-animation: slideout .5s; /* Opera */
    animation: slideout .5s;
  }

  @keyframes slideout {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }

  /* Firefox */
  @-moz-keyframes slideout {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }

  /* Safari and Chrome */
  @-webkit-keyframes slideout {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }

  /* Internet Explorer */
  @-ms-keyframes slideout {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }

  .dropdown-content.show {
    position: absolute;
    background-color: #f1f1f1;
    width: 100%;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: 0;
    top: 64px;
    -webkit-animation: slidein .5s; /* Safari and Chrome */
    -moz-animation: slidein .5s; /* Firefox */
    -ms-animation: slidein .5s; /* Internet Explorer */
    -o-animation: slidein .5s; /* Opera */
    animation: slidein .5s;
  }

  @keyframes slidein {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  /* Firefox */
  @-moz-keyframes slidein {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  /* Safari and Chrome */
  @-webkit-keyframes slidein {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  /* Internet Explorer */
  @-ms-keyframes slidein {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}

  .menu-button {
    padding: 0;
  }

  .menu-button a {
    text-align: center;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #055520;
    font-weight: bold;
    text-decoration: none;
    padding: 7vw 0;
  }

  .menu .menu-button.active a {
    color: #CFB53B;
  }
}